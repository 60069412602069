<template>
  <div class="container">
    <el-swiper :src='imgSrc'
               :info1="info1"></el-swiper>
    <div class="container1">
      <div class="main">
        <el-tabs v-model="activeName"
                 @tab-click="handleClick">
          <el-tab-pane v-for="item in tabList"
                       :key='item.name'
                       :label="item.label"
                       :name="item.name">
            <my-swiper :imgList="imgList"></my-swiper>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc: require('@/assets/images/banner4.png'),
      info1: {
        dsc: 'Successful Cases',
        title: '成功案例'
      },
      activeName: 'first',
      tabList: [
        { label: '尚盈车联', name: 'first' },
        { label: '乐享小铺', name: 'second' },
        { label: '尚盈名品', name: 'third' },
      ],
      imgList: [
        { src: require('@/assets/images/success_part1_1.jpg') },
        { src: require('@/assets/images/success_part1_2.jpg') },
        { src: require('@/assets/images/success_part1_3.jpg') },
      ],
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    // tab标签页点击事件
    handleClick (tab, event) {
      if (this.activeName == 'first') {
        this.imgList = [
          { src: require('@/assets/images/success_part1_1.jpg') },
          { src: require('@/assets/images/success_part1_2.jpg') },
          { src: require('@/assets/images/success_part1_3.jpg') },
        ]
      } else if (this.activeName == 'second') {
        this.imgList = [
          { src: require('@/assets/images/success_part2_1.jpg') },
          { src: require('@/assets/images/success_part2_2.jpg') },
          { src: require('@/assets/images/success_part2_3.jpg') },
        ]
      } else {
        this.imgList = [
          { src: require('@/assets/images/success_part3_1.jpg') },
          { src: require('@/assets/images/success_part3_2.jpg') },
          { src: require('@/assets/images/success_part3_3.jpg') },
        ]
      }
    },
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/success-demo.scss";
</style>